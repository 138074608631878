import React, { useState } from "react";
import { Form, FormContainer, StyledFormButton, StyledInput } from "./styles";

type PpTransitionalCTACollectEmail = {
  buttonText: string;
  inputPlaceholder: string;
  onSubmit: (e: React.FormEvent<HTMLInputElement>, arg: string) => void;
};

export function TransitionalCTACollectEmail({
  buttonText,
  inputPlaceholder,
  onSubmit,
}: PpTransitionalCTACollectEmail): React.ReactElement {
  const [inputValue, setInputValue] = useState<string>("");

  return (
    <FormContainer item>
      <Form
        onSubmit={(e: React.FormEvent<HTMLInputElement>): void =>
          onSubmit(e, inputValue)
        }
      >
        <StyledInput
          placeholder={inputPlaceholder}
          type="email"
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setInputValue(e.target.value)
          }
        />
        <StyledFormButton type="submit" variant="contained">
          {buttonText}
        </StyledFormButton>
      </Form>
    </FormContainer>
  );
}
